import Month from '../models/months';
import MonthlyOffer from '../models/monthly-offer';

const offersCollection: MonthlyOffer[] = [
  // ##### TEMPLATE
  // {
  //   month: Month.XX,
  //   year: 2025,
  //   offers: [
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.png',
  //       offerPrice: 0.0,
  //     },
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.png',
  //       offerPrice: 0.0,
  //     },
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.png',
  //       offerPrice: 0.0,
  //     },
  //   ],
  // },
  // #####
  {
    month: Month.April,
    year: 2025,
    offers: [
      {
        title: 'Gaviscon Dual',
        description: 'bei Sodbrennen, 12 Beutel',
        imgFile: 'gaviscon_dual_12b.png',
        offerPrice: 9.99,
      },
      {
        title: 'Voltaren Actigo',
        description: 'Schmerzgel, 60 g Gel',
        imgFile: 'voltaren_actigo_schmerzgel_mit.png',
        offerPrice: 9.99,
      },
    ],
  },
];

export default offersCollection;
